.article_img {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 62px;
}
.article_img_in {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.article-link {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #0f6fd9;
}

.article-text-bold{
  font-weight: 600;
}

@media screen and (max-width: 1070px) {
  .article_img {
    margin-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .article-link {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  .article_img {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .article_img_in {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
