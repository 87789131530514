.flex-row-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.flex-row-container > .flex-row-item {
  width: 224px;
  height: 409px;
  margin: 5px 5px;
  border-radius: 8px;
  padding: 1px;
}

.dark {
  background: linear-gradient(180deg, #1784b8 0%, #9282eb 100%);
}
.not_dark {
  background: #0b323e;
}
.flex-row-item {
  position: relative;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 30px 0;
  cursor: pointer;
}
.img-funding-body {
  position: absolute;
  bottom: 1px;
  max-width: 223px;
  max-height: 310px;
  height: 100%;
  width: 100%;
}
.h2_funding {
  margin: 20px 5px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: #ffffff;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0b323e28;
}

.overlay {
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 50rem;
  z-index: 10;
  border-radius: 4px;
  overflow: hidden;
}
.overlay__header {
  display: flex;
  justify-content: flex-end;
  padding: 4rem 2rem 0 2rem;
  cursor: pointer;
}

.overlay__content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 2rem 0;
}
@media screen and (max-width: 600px) {
  .flex-row-container {
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
    max-width: 860px;
    max-height: 458px;
    min-width: 450px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .flex-row-container > .flex-row-item {
    width: 224px;
    height: 360px;
    margin: 5px 5px;
  }
  .flex-row-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    width: 209px;
    height: 458px;
    margin: 0 5px;
    padding: 5px;
    flex: 0 0 auto;
    width: 129px;
    height: 208px;
  }
  .flex-row-container::-webkit-scrollbar {
    display: none;
  }

  .overlay {
    top: 48%;
    left: 55%;
    width: 90%;
    max-width: 30rem;
  }

  .overlay__header {
    padding: 0;
  }

  .overlay__content {
    padding: 0 2rem 2rem 0;
  }

  .overlay_img {
    width: 100%;
    height: 100%;
  }
}
