.button_place_order {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 208px;
    height: 48px;
    border-radius: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    cursor: pointer;
    color: #ffffff;
}
.btn_wrapp_order {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container_order {
    display: flex;
    justify-content: center;
}

.content_wrapper_order {
    max-width: 864px;
    width: 100%;
}

.dsa_order_process_content {
    margin: 64px 0 94px 0;

}

@media screen and (max-width: 1024px) {
    .container_order {
        margin: 0 10px;
    }
}

@media screen and (max-width: 600px) {
    .container_order {
        margin: 0 2%;
    }

}
