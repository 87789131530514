.card_article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 277px;
  width: 100%;
  height: 304px;
}
.card_article a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0b323e;
}

.card_app_bar {
  display: flex;
  flex-direction: column;
  max-width: 277px;
  width: 100%;
  margin-bottom: 5px;
  height: 100%;
}

.card_app_bar img {
  max-width: 277px;
  max-height: 170px;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
.footer_article_card {
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0b323e;
}

@media screen and (max-width: 600px) {
  .card_article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 277px;
    width: 100%;
    height: 270px;
  }
  .card_article a {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
  .footer_article_card {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
}
