.alert-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 400ms, transform 400ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}
.page_none {
    display: none;
}
.page {
    display: flex;
    flex-direction: column;
    /* min-height: 3000px; */
    overflow: hidden;
    width: 100%;
    max-width: 2200px;
    margin: 0 auto;
    background-image: url('./assets/img/leftBack.svg' ) , url('./assets/img/rightBack.svg') ;
    background-repeat: no-repeat;
    background-position: left, right;
    background-position-y: 378px, 378px; 
}
.page_none_pic {
    display: flex;
    flex-direction: column;
    min-height: 2500px;
    overflow: hidden;
    width: 100%;
    max-width: 2200px;
    margin: 0 auto;
}
.page_home {
    display: flex;
    flex-direction: column;
    min-height: 3000px;
    overflow: hidden;
    width: 100%;
    max-width: 2200px;
    margin: 0 auto;
    
}
.main_content {
    flex: 1 1 auto;
}





@media screen and (max-width: 1370px) {
    .page {
        background-image: none ;
    }
  
}

@media screen and (max-width: 600px) {
    .page {
        min-height: 1000px;
    }
    .page_home{
        min-height: 1000px;
    }
    .page_none_pic {
        min-height: 1000px;
    }
}
