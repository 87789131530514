.mentotring_img img {
  width: 100%;
  max-height: 494px;
  height: 100%;
  margin-top: 46px;
}

.mentoring-text {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}
