.ul-insurance li {
    margin-left: 15px;
}

.incurance_link {
    color: #2194ba;
}

@media screen and (max-width: 600px) {
    .incurance_link {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
    }
}
