.scholarcy_img_block {
  margin: 0 auto;
  max-width: 480px;
  max-height: 320px;
}
.scholarcy_img {
  width: 100%;
  height: 100%;
}


.just_btn_wr {
  display: flex;
  justify-content: flex-end;
}

.justification {
  border-radius: 24px;
  width: 224px;
  height: 48px;
  font-style: normal;
  padding: 0.5rem;
  cursor: pointer;
  text-align: center;
}
.scholarcy_link {
  font-weight: 400;
  font-size: 20px;

  color: #ffffff;
}

.scholarcy_content_ul {
  display: flex;
  align-items: center;
  max-width: 864px;
  width: 100%;
}
.scholarcy_card_img {
  margin-right: 20px;
}
.scholarcy_card_title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #0b323e;
}

.scholarcy_logos {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.logo_link {
  margin-right: 24px;
}
.logo_link img {
  max-width: 51px;
  max-height: 51px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .justification {
    width: 180px;
    height: 38px;
    font-weight: 400;
    font-size: 15px;
    /* or 120% */
    text-align: center;
  }
  .scholarcy_card_img {
    margin-right: 0;
  }
  .logo_link {
    margin-right: 16px;
  }
}
