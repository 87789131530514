.home-link{
    text-decoration: underline;
    color:#FFFFFF;
    font-weight: 600;
}
.home_container {
    width: 100%;
    height: 100%;
    max-width: 2200px;
    margin: 0 auto;
}

.home_container_head {
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
}
.home_head_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 20px 0 10px;
}
.home_content_wrapper{
    max-width: 969px;
}

.home_head {
    min-height: 719px;
    background-image: url('../../assets/img/home.svg');
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 1305px 100%;
    display: flex;
    align-items: center;
}

.home_start_btn {
    width: 169px;
    height: 48px;
    border-radius: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home_start_btn a {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;
    color: #ffffff;
}
.home_main_content_wrapper {
    background-color: #0b323e;
    width: 100%;
}
.home_main_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 864px;
    width: 100%;
    max-height: 300px;
    margin: 100px 0;
}
.home_main_title img {
    width: 100%;
    height: 100%;
}

.home_main_title_h2 {
    width: 860px;
    height: 56px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    color: #ffffff;
    width: 100%;
}
.home_main_title_h2_content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #ffffff;
    width: 100%;
}

.focus_yog {
    width: 100%;
    max-height: 600px;
    margin-bottom: 106px;
    margin-top: 56px;
}
.focus_content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.focus_img {
    align-self: flex-start;
    height: 100%;
    max-width: 480px;
    width: 100%;
}

.develop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 864px;
    width: 100%;
    margin-bottom: 100px;
}
.develop_img {
    max-width: 375px;
    max-height: 325px;
    width: 100%;
    height: 100%;
    align-self: center;
}
.develop_title_h2_content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    box-sizing: border-box;
    color: #ffffff;
    width: 100%;
}
.develop_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 433px;
    width: 100%;
}
.improve {
    display: flex;
    align-items: flex-end;
    max-height: 544px;
    height: 100%;
    margin-bottom: 106px;
    max-width: 917px;
    width: 100%;
}
.improve_img {
    max-width: 428px;
    max-height: 373px;
    width: 100%;
    height: 100%;
    align-self: flex-end;
}
.home_main_p {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    max-width: 433px;
    width: 100%;
    color: #ffffff;
    box-sizing: border-box;
    display: block;
}
.home_main_p_smart {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    width: 100%;
    color: #ffffff;
    box-sizing: border-box;
}
.home_main_img_wr {
    max-width: 790px;
    max-height: 1016px;
    margin: 120px 0 120px 0;
}
.calc_img {
    width: 100%;
    height: 100%;
}
.mr_content_home {
    margin: 20px 0;
}

@media screen and (max-width: 1024px) {
    .home_main_p {
        margin-bottom: 80px;
    }
    .home_main_p_smart {
        margin-bottom: 80px;
    }
}
@media screen and (max-width: 800px) {
    .focus_yog {
        max-height: 600px;
        margin-bottom: 52px;
    }
    .focus_content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .focus_img {
        width: 325px;
        height: 233px;
        align-self: center;
        margin-bottom: 30px;
    }

    .develop {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 24px;
        margin-top: 56px;
    }
    .develop_img {
        width: 285px;
        height: 233px;
        align-self: center;
        margin-right: 0;
    }
    .improve {
        display: flex;
        flex-direction: column;
        margin-bottom: 106px;
    }
    .improve_img {
        align-self: center;
        width: 325px;
        height: 233px;
    }
}

@media screen and (max-width: 600px) {
    .mr_content_home {
        text-align: center;
    }
    .home_head {
        min-height: 564px;
        max-width: 600px;
        background-image: url('../../assets/img/home.svg');
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: 799px 78%;
        display: flex;
        align-items: flex-start;
    }
    .home_head_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 24px 10px 0 10px;
    }

    .home_start_btn {
        width: 106px;
        height: 32px;
        border-radius: 29px;
    }
    .home_start_btn a {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
    }

    .home_main_title_h2 {
        max-width: 100%;
        width: 100%;
        height: 20px;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home_main_title_h2_content {
        max-width: 100%;
        width: 100%;
        height: 20px;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .develop_title_h2_content{
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
    }
    .home_main_p {
        font-size: 15px;
        line-height: 25px;
        margin: 24px 0;
    }
    .home_main_p_smart {
        font-size: 15px;
        line-height: 25px;
        margin: 24px 0;
    }
    .focus_yog {
        max-height: 600px;
        margin-bottom: -62px;
    }
    .focus_img {
        width: 201px;
        height: 112px;
        align-self: center;
    }
    .develop_img {
        width: 175px;
        height: 152px;
        align-self: center;
        margin-right: 0;
    }
    .improve {
        display: flex;
        flex-direction: column;
        margin-bottom: 72px;
    }
    .improve_img {
        align-self: center;
        width: 175px;
        height: 151.61px;
    }
    .home_main_title {
        margin: 32px 0;
    }
}

@media screen and (max-width: 500px) {
    .home_head {
        min-height: 512px;
        max-width: 600px;
        background-image: url('../../assets/img/home.svg');
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: 651px 70%;
        display: flex;
        align-items: flex-start;
    }
}
