.calc_wr {
    max-width: 790px;
    min-height: 847px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.main_calc {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 30px;
}
.calc_block1 {
    height: 273px;
    width: 278px;
    background-image: url('../../assets/img/calc_main.svg');
    background-repeat: no-repeat;
    background-size: 278px 273px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    z-index: 0;
}
.calc_content_mr {
    margin-bottom: 110px;
    margin-right: 5%;
    position: absolute;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
}
.calc_block1 input {
    width: 40%;
    background: #8000d5;
    color: #ffffff;
    font-size: 40px;
    line-height: 30px;
    outline: none;
    margin-top: 65px;
    margin-right: 10%;
}
.first_arrow {
    position: relative;
    top: 40px;
}
.first_arrow_img {
    width: 111.5px;
    height: 143.08px;
}
.center_arrow {
    position: relative;
    left: -160px;
    top: 174px;
}
.center_arrow_img {
    width: 44px;
    height: 112px;
}
.right_arrow {
    position: relative;
    top: 40px;
    left: -50px;
}
.right_arrow_img {
    width: 111.5px;
    height: 143.08px;
}
.small_arrow {
    position: relative;
    top: -126.5px;
    left: 270px;
}
.small_arrow_2 {
    position: relative;
    top: -137.5px;
    left: 270px;
}
.small_arrow_3 {
    position: relative;
    top: -147.5px;
    left: 270px;
}
.small_arrow_img {
    width: 20px;
    height: 40px;
}
.introduction_value {
    background-color: #585be0;
    width: 260px;
    height: 97px;
    border-radius: 61px;
    color: #ffffff;
    text-align: center;
    position: relative;
    top: -38px;
    left: -88px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.conclusion_value {
    background-color: #585be0;
    width: 260px;
    height: 97px;
    border-radius: 61px;
    color: #ffffff;
    text-align: center;
    position: relative;
    top: -137px;
    left: 370px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.essay_body_value {
    background-color: #585be0;
    width: 260px;
    height: 97px;
    border-radius: 61px;
    text-align: center;
    position: relative;
    top: -125px;
    left: 147px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.paragraph_value {
    background-color: #585be0;
    width: 197px;
    height: 79px;
    border-radius: 61px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.paragraph_position_1 {
    top: -135px;
    left: 180px;
}
.paragraph_position_2{
    top: -145px;
    left: 180px;
}
.paragraph_position_3{
    top: -155px;
    left: 180px;
}
.value_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #ffffff;
}
.value_p_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
}
.value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 30px;
    color: #ffffff;
}
.p_value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    text-align: center;

    color: #ffffff;
}
@media screen and (max-width: 780px) {
    .small_arrow,.small_arrow_img,.paragraph_value,.p_value,.value_p_title{
        display: none;
    }
    .calc_wr {
        min-height: 350px;
    }
    .calc_block1 {
        height: 170px;
        width: 178px;
        background-image: url('../../assets/img/calc_main.svg');
        background-repeat: no-repeat;
        background-size: 178px 170px;
    }

    .calc_content_mr {
        margin-bottom: 75px;
        margin-right: 20%;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
    }
    .calc_block1 input {
        font-size: 30px;
        line-height: 20px;
        margin-top: 45px;
    }

    .first_arrow {
        position: relative;
        top: 30px;
        left: 3px;
    }
    .first_arrow_img {
        width: 80.5px;
        height: 143.08px;
    }
    .center_arrow {
        position: relative;
        left: -105px;
        top: 114px;
    }
    .center_arrow_img {
        width: 34px;
        height: 90px;
    }
    .right_arrow {
        position: relative;
        top: 30px;
        left: -40px;
    }
    .right_arrow_img {
        width: 80.5px;
        height: 143.08px;
    }

    .introduction_value {
        width: 180px;
        height: 57px;
        top: -15px;
        left: -40px;
    }
    .conclusion_value {
        width: 180px;
        height: 57px;
        top: -70px;
        left: 260px;
    }
    .essay_body_value {
        width: 180px;
        height: 57px;
        top: -60px;
        left: 100px;
    }

    .value_title {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #ffffff;
    }
    .value {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        color: #ffffff;
    }
}
@media screen and (max-width: 530px) {
    .calc_wr {
        min-height: 280px;
    }
    .main_calc {
        left: 12px;
    }
    .calc_block1 {
        width: 118.53px;
        height: 117.66px;
        background-size: 117.66px 118.53px;
    }
    .calc_content_mr {
        margin-bottom: 48px;
        margin-right: 20%;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }
    .calc_block1 input {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }

    .first_arrow {
        position: relative;
        top: 22px;
        left: -2px;
    }
    .first_arrow_img {
        width: 48.41px;
        height: 61.67px;
    }
    .center_arrow {
        position: relative;
        left: -70px;
        top: 86px;
    }
    .center_arrow_img {
        width: 33.76px;
        height: 48.49px;
    }
    .right_arrow {
        position: relative;
        top: 22px;
        left: -35px;
    }
    .right_arrow_img {
        width: 48.41px;
        height: 61.67px;
    }

    .introduction_value {
        width: 123px;
        height: 57.08px;
        top: -15px;
        left: -40px;
    }
    .conclusion_value {
        width: 123px;
        height: 57.08px;
        top: -70px;
        left: 160px;
    }
    .essay_body_value {
        width: 123px;
        height: 57.08px;
        top: -65px;
        left: 65px;
    }

    .value_title {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
    }
    .value {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }
}
