* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  /* scroll-behavior: smooth; */
  height: 100%;
  max-height: 300vh;
}

.main_container_yellow {
  width: 100%;
  height: 100%;
  background-color: #fff9b2;
}

.main_container_blue {
  width: 100%;
  height: 100%;
  background-color: #d0f1ff;
}

.main_container_purple {
  width: 100%;
  height: 100%;
  background-color: #f3d9ff;
}

.main_container_dark_yellow {
  width: 100%;
  height: 100%;
  background-color: #0a0a0a;
}
.main_container_green {
  width: 100%;
  height: 100%;
  background-color: #deffdb;
}
.main_container_dark_light {
  width: 100%;
  height: 100%;
  background-color: #0a0a0a;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.main_container_dark_yellow h1 {
  color: #ebff00f0;
}
.main_container_dark_yellow h2 {
  color: #ebff00f0;
}
.main_container_dark_yellow .text_size {
  color: #ebff00f0;
}

.main_container_dark_light h1 {
  color: #ffffff;
}
.main_container_dark_light h2 {
  color: #ffffff;
}
.main_container_dark_light .text_size {
  color: #ffffff;
}
.btn_bg {
  background: linear-gradient(90deg, #1070da 15.32%, #5e26ff 65.18%);
}
.contact_us_btn_wr {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.contact_us_button .btn {
  border-radius: 24px;
  height: 48px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  margin: 2px 1px;
}
h1 {
  display: flex;
  justify-content: flex-start;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 52px;
  align-items: center;
  color: #0b323e;
}
h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;

  display: flex;
  align-items: center;

  color: #0b323e;
}
.mr_head {
  margin: 120px 0 56px 0;
}
.mr_content {
  margin: 24px 0;
}
.content_wrapper_height {
  height: 56px;
}
.text_size {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #0b323e;
}

.container {
  display: flex;
  max-width: 1440px;
  justify-content: center;
  margin: 0 auto;
}

.content_wrapper {
  width: 864px;
  margin-bottom: 56px;
}

.quote-mr {
  margin: 0 24px 56px;
}
.quote-wrap {
  display: block;
  position: relative;
  padding: 32px 54px;
  max-width: 804px;
  max-height: 382px;
  height: 100%;
  width: 100%;
  background: #4ec5d1;
  background: linear-gradient(180deg, #4893b7 0%, #a47fd1 100%);
  color: #fff;
  border-radius: 1rem;
}
.quote-wrap::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: -1rem;
  left: -1rem;
  width: 50%;
  height: 80%;
  border: 2px solid #222222;
  border-right: none;
  border-bottom: none;
  border-top-left-radius: inherit;
}
.quote-wrap::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 1rem;
  left: 1rem;
  width: 100%;
  height: 100%;
  border: 2px solid #222222;
  border-radius: inherit;
}

.quote-text {
  position: relative;
  z-index: 1;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-size: 32px;
  line-height: 50px;
  color: #ffffff;
}
.quote-text::before {
  content: '';
  position: absolute;
  z-index: -1;
  font-size: 5rem;
  top: -1rem;
  left: -1rem;
  width: 3rem;
  height: 3rem;
  background-image: url("data:image/svg+xml,%3Csvg width='65' height='63' viewBox='0 0 65 63' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1334_1560)'%3E%3Cpath d='M14.8822 3.92041C18.4836 3.92041 21.7157 5.66617 24.5323 9.18129C27.3719 12.6728 28.7802 17.2968 28.7802 23.053C28.7802 27.9365 26.1945 35.415 21.0463 45.4649C15.898 55.5384 12.0426 60.5633 9.54925 60.5633C8.14098 60.5633 7.42531 59.8084 7.42531 58.3221C7.42531 57.9211 8.90283 54.6655 11.8348 48.5317C14.7898 42.398 16.2442 37.5853 16.2442 34.141C16.2442 31.4752 14.582 29.2811 11.2346 27.5826C7.91012 25.884 5.67075 24.1146 4.56261 22.2745C3.45446 20.4344 2.90039 18.4291 2.90039 16.3059C2.90039 12.4605 4.00853 9.4172 6.22483 7.22321C8.4642 5.0292 11.35 3.92041 14.8822 3.92041Z' fill='black' fill-opacity='0.2'/%3E%3C/g%3E%3Cpath d='M47.9818 4C51.5832 4 54.8153 5.74576 57.6319 9.26088C60.4715 12.7524 61.8798 17.3764 61.8798 23.1326C61.8798 28.016 59.2941 35.4945 54.1459 45.5445C48.9976 55.618 45.1422 60.6429 42.6489 60.6429C41.2406 60.6429 40.5249 59.888 40.5249 58.4017C40.5249 58.0007 42.0024 54.7451 44.9344 48.6113C47.8895 42.4776 49.3439 37.6649 49.3439 34.2206C49.3439 31.5548 47.6816 29.3607 44.3342 27.6621C41.0097 25.9636 38.7704 24.1942 37.6622 22.3541C36.5541 20.514 36 18.5087 36 16.3855C36 12.5401 37.1081 9.49679 39.3244 7.3028C41.5638 5.10879 44.4496 4 47.9818 4Z' fill='black' fill-opacity='0.2'/%3E%3Cdefs%3E%3CclipPath id='clip0_1334_1560'%3E%3Crect width='27' height='58' fill='white' transform='translate(2 3)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.blue-link {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-decoration-line: underline;
  color: #2194ba;
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 0 10px;
  }
  .quote-wrap {
    padding: 32px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    margin: 0 4%;
  }
  h1 {
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
  }
  h2 {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0b323e;
  }

  .text_size {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #0b323e;
  }
  .blue-link {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  .mr_head {
    margin: 44px 0 20px 0;
  }
  .mr_content {
    margin: 15px 0;
  }
}

@media screen and (max-width: 420px) {
  .quote-wrap {
    padding: 7px 12px;
  }
  .quote-text {
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
  }
}
