.contact_content {
  display: flex;
  justify-content: space-between;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.contact_block {
  margin-top: 80px;
}
.article-text-bold {
  font-weight: 600;
}
.text_contact {
  margin: 20px 0;
}
.map {
  max-width: 529px;
  max-height: 435px;
  display: flex;
  justify-content: center;
  padding: 30px;
}
.map_fram {
  width: 400px;
  height: 435px;
}
.link_contact {
  color: #0b323e;
  transition: all 400ms ease;
}
.link_contact:hover {
  color: #0f6fd9;
}
@media screen and (max-width: 998px) {
  .contact_content {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .contact_block {
    margin-top: 40px;
  }
  .text_contact {
    margin: 10px 0;
  }
  .map_fram {
    width: 100%;
    height: 450px;
  }

}
