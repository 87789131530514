.tags_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tag {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 24px;
  gap: 10px;
  margin: 16px 16px 0 0;
  border: 1px solid transparent;
  background: #fff;
  border-radius: 30px;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #0f6fd9;
}
.tag::after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(90deg, #1070da 15.32%, #5e26ff 65.18%);
  content: '';
  z-index: -1;
  border-radius: 30px;
}

.tag_active {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 24px;
  gap: 10px;
  margin: 16px 16px 0 0;
  border: 1px solid transparent;
  background: linear-gradient(90deg, #1070DA 15.32%, #5E26FF 65.18%);
  border-radius: 30px;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}
.tag_active::after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(90deg, #1070da 15.32%, #5e26ff 65.18%);
  content: '';
  z-index: -1;
  border-radius: 30px;
}
@media screen and (max-width: 600px) {
  .tags_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .tag {
    padding: 4px 15px;
    margin: 8px 16px 0 0;
    border-radius: 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
  .tag_active {
    padding: 4px 15px;
    margin: 8px 16px 0 0;
    border-radius: 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
}
