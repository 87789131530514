.coming_wr {
    background-image: url('../../assets/img/coming_bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    max-width: 2200px;
    margin: 50px auto 0 auto;
}

.cm_wrapp_img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 900px;
}
.coming_soon_img {
    width: 100%;
    height: 100%;
    max-width: 760px;
    max-height: 493px;
}
