.footer_wrapper {
    max-width: 2200px;
    width: 100%;
    min-height: 350px;
    height: 100%;
    margin: 0 auto;
    background: #0b323e;
}
.footer {
    max-width: 1440px;
    max-height: 350px;
    margin: 0 auto;
}
.footer_border_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.footer_border_wrapper_bottom {
    margin-top: 44px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.footer_mr {
    margin: 0 20px;
}
.footer_border {
    max-width: 1312px;
    width: 100%;
    height: 1px;
    background-color: #284a55;
    display: flex;
    justify-content: center;
}
.footer_content {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
}

.footer_logo {
    max-width: 266px;
    max-height: 64px;
    width: 100%;
    height: 100%;
    margin-top: 48px;
}
.foot_head_content {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}
.footer_foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}
.footer_foot_content {
    display: flex;
    justify-content: space-between;
    max-width: 240px;
    width: 100%;
}
.foot_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 8px;
}

.foot_link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 8px;
    transition: all 400ms ease;
    cursor: pointer;
}

.foot_link:hover {
    color: #ffffff65;
}

@media screen and (max-width: 1024px) {
    .footer_border {
        max-width: 896px;
        width: 100%;
    }
    .footer_content {
        max-width: 708px;
    }
}

@media screen and (max-width: 600px) {
    .footer {
        max-height: 100%;
    }
    .footer_logo {
        max-width: 152px;
        max-height: 37px;
        width: 100%;
        height: 100%;
        margin-top: 33px;
    }
    .foot_head_content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer_head_address {
        margin-right: 25px;
    }
    .footer_foot {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer_border_wrapper_bottom {
        margin-top: 18px;
    }

    .foot_text {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 6px;
    }

    .foot_link {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 6px;
    }
}
