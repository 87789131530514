.spinner_wrapper {
    margin: 0;
}

.spinner {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 20px solid rgba(255, 255, 255, 0.1);
    border-top-color: #09d;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
