.scrolling-wrapper {
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
    max-width: 860px;
    max-height: 458px;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    width: 209px;
    height: 458px;
    color: #ffffff;
    padding: 5px;
    flex: 0 0 auto;
    border-radius: 8px;
    padding: 8px;
    margin-right: 5px;
}
.h2_order {
    color: #ffffff;
}
.scrolling-wrapper::-webkit-scrollbar {
    display: none;
}
.num{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
}
.order_place_bg {
    background: linear-gradient(180deg, #0B323E 0%, #A185D6 100%);
    
}
.upgrade_bg {
    background: linear-gradient(180deg, #40687B 0%, #7382FE 100%);

}
.delivery_bg {
    background: linear-gradient(180deg, #4893B7 0%, #A47FD1 100%);
}
.feedback_bg {
    background: linear-gradient(180deg, #1784B8 0%, #9282EB 100%);
}
@media screen and (max-width: 600px) {
    .card {
        width: 168px;
        height: 353px;
       
    }
}
