.articles_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* margin: 100px 0; */
}

.students_wrapper{
  min-height: 1300px;
}

@media screen and (max-width: 850px) {
  .articles_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 40px 0 56px 0;
  }
}
