.video {
  margin: 56px 0;
}
.video iframe {
  width: 100%;
  height: 512px;
}
.grammarly_img {
  width: 100%;
  height: 100%;
  margin: 24px 0;
}

.grammarly_buttons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 90px auto;
  max-width: 100%;
}
.contact_us_btn_wr {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 56px 0;
}
.contact_us_button {
  border-radius: 24px;
  height: 48px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  margin: 2px 1px;
}
.grammarly_button {
  border-radius: 24px;
  height: 48px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  margin: 2px 1px;
}
.grammarly_button_active {
  background: #2194ba88;
  border-radius: 24px;
  min-width: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  margin: 2px 1px;
}

.grammarly_btn_content {
  height: 596px;
  max-height: 596px;
}

@media screen and (max-width: 600px) {
  .contact_us_btn_wr {
    margin: 24px 0;
  }
  .grammarly_buttons {
    margin: 20px auto;
    max-width: 700px;
  }
  .grammarly_button {
    border-radius: 24px;
    min-width: 100px;
    height: 38px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* or 120% */
    margin: 3px 3px;
    text-align: center;
    color: #ffffff;
  }
  .grammarly_button_active {
    background: #2194ba88;
    border-radius: 24px;
    min-width: 100px;
    height: 38px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* or 120% */
    margin: 3px 3px;
    text-align: center;
    color: #ffffff;
  }
}
