*::before,
*::after {
  margin: 0;
  padding: 0;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2rem 2rem 0 2rem;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  z-index: 2;
}

.nav__listlogo img {
  width: 249px;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav__list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 2rem;
  background: #f2f2f2;
  border-radius: 50px;
  padding: 0.5rem;
  width: 854px;
  height: 56px;
}
.main_container_dark_yellow .nav__list,
.main_container_dark_light .nav__list {
  background: #021116;
}
.nav__listitem {
  list-style: none;
  font-weight: bold;
  position: relative;
  padding: 1.5rem 0;
  cursor: pointer;
  transition: all 400ms ease;
}
.nav__listitem .flag {
  width: 32px;
  height: 32px;
  margin-top: 20%;
}

.nav__listitem:hover::after,
.nav__listitem:focus::after {
  width: 80%;
}

.nav__listitem:hover ul,
.nav__listitem:focus ul {
  opacity: 1;
  visibility: visible;
}

.nav__listitemdrop {
  position: absolute;
  top: 4.5rem;
  left: -1.7rem;
  background: #ffffff;
  width: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.main_container_dark_yellow .nav__listitemdrop_students_focus,
.main_container_dark_yellow .nav__listitemdrop_software_focus,
.main_container_dark_yellow .nav__listitemdrop,
.main_container_dark_yellow .nav__listitemdrop_assessor,
.main_container_dark_yellow .nav__listitemdrop_assessor_focus,
.main_container_dark_yellow .nav__listitemdrop_resources,
.main_container_dark_yellow .nav__listitemdrop_resources_focus,
.main_container_dark_yellow .nav__listitemdrop_color,
.main_container_dark_yellow .flag_drop,
/*  */
.main_container_dark_light .nav__listitemdrop,
.main_container_dark_light .nav__listitemdrop_students_focus,
.main_container_dark_light .nav__listitemdrop_software_focus,
.main_container_dark_light .nav__listitemdrop_assessor,
.main_container_dark_light .nav__listitemdrop_assessor_focus,
.main_container_dark_light .nav__listitemdrop_resources,
.main_container_dark_light .nav__listitemdrop_resources_focus,
.main_container_dark_light .nav__listitemdrop_color,
.main_container_dark_light .flag_drop {
  background: #0b323e;
}

.nav__listitemdrop li {
  list-style: none;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}
.nav__listitemdrop_students_focus {
  position: absolute;
  top: 4.5rem;
  left: -1.7rem;
  background: #ffffff;
  width: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.nav__listitemdrop_students_focus li {
  list-style: none;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}
.nav__listitemdrop_software_focus {
  position: absolute;
  top: 4.5rem;
  left: -4rem;
  background: #ffffff;
  border-radius: 0.2rem;
  width: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.nav__listitemdrop_software_focus li {
  list-style: none;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}

.nav__listitemdrop_assessor {
  position: absolute;
  top: 4.5rem;
  left: -4rem;
  background: #ffffff;
  border-radius: 0.2rem;
  width: 20rem;
  height: 12rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.nav__listitemdrop_assessor li {
  list-style: none;
  padding: 0.5rem 0rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}
.nav__listitemdrop_assessor_focus {
  position: absolute;
  top: 4.5rem;
  left: -4rem;
  background: #ffffff;
  border-radius: 0.2rem;
  width: 20rem;
  height: 12rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}
.nav__listitemdrop_assessor_focus li {
  list-style: none;
  padding: 0.5rem 0rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}

/*  */
.nav__listitemdrop_resources {
  position: absolute;
  top: 4.5rem;
  left: -4rem;
  background: #ffffff;
  border-radius: 0.2rem;
  width: 20rem;
  height: 8rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.nav__listitemdrop_resources li {
  list-style: none;
  padding: 0.5rem 0rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}
.nav__listitemdrop_resources_focus {
  position: absolute;
  top: 4.5rem;
  left: -4rem;
  background: #ffffff;
  border-radius: 0.2rem;
  width: 20rem;
  height: 8rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}
.nav__listitemdrop_resources_focus li {
  list-style: none;
  padding: 0.5rem 0rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}
/*  */
.main_container_dark_yellow .nav__listitemdrop {
  position: absolute;
  top: 4.5rem;
  left: -4rem;
  border-radius: 0.2rem;
  width: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.nav__listitemdrop_color {
  position: absolute;
  top: 4.5rem;
  left: -4rem;
  background: #ffffff;
  border-radius: 0.2rem;
  width: 12rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
  color: #0b323e;
}

.nav__listitemdrop_color li {
  list-style: none;
  padding: 0.5rem 0.5rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}
.nav__listitemdrop_color_item {
  display: flex;
  justify-content: space-around;
}
.nav__listitemdrop_color_reset {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 0.5rem 0.8rem;
  color: #0b323e;
}

.nav__listitemdrop_color li .yellow_theme {
  background: #fff9b2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
}
.nav__listitemdrop_color li .blue_theme {
  background: #d0f1ff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
}
.nav__listitemdrop_color li .purple_theme {
  background: #f3d9ff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
}
.nav__listitemdrop_color li .dark_yellow_theme {
  color: #e6ff4a;
  background: #000000;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
}
.nav__listitemdrop_color li .dark_light_theme {
  color: #ffffff;
  background: #000000;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
}
.nav__listitemdrop_color li .green_theme {
  background: #deffdb;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
}

.flag_drop {
  position: absolute;
  top: 5rem;
  left: -4rem;
  background: #ffffff;
  border-radius: 0.2rem;
  width: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.flag_drop li {
  list-style: none;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}
.flag_drop li .translate {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flag_drop li .translate span {
  margin-left: 15px;
}

.button {
  border-radius: 24px;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
  width: 168px;
  height: 48px;
  text-align: center;
}

.button span.login a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin: 3px;
  color: #ffffff;
}
.nav__listitem a {
  color: #14323e;
  transition: all 400ms ease;
  height: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.nav__listitem a:hover {
  color: #0f6fd9;
  cursor: pointer;
}

.span_nav_item {
  color: #14323e;
  transition: all 400ms ease;
  height: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.span_nav_item:hover {
  cursor: pointer;
}

.main_container_dark_yellow .nav__list span,
.main_container_dark_yellow .nav__list a,
.main_container_dark_yellow .nav__list .nav__listitemdrop_color_reset {
  color: #ebff00;
  cursor: pointer;
}
.main_container_dark_yellow .nav__list span:hover,
.main_container_dark_yellow .nav__list a:hover,
.main_container_dark_yellow .nav__list .nav__listitemdrop_color_reset:hover {
  cursor: pointer;
}


/*  */

.main_container_dark_light .nav__list span,
.main_container_dark_light .nav__list a {
  color: #ffffff;
  cursor: pointer;
}
.main_container_dark_light .nav__list span:hover,
.main_container_dark_light .nav__list a:hover {
  cursor: pointer;
}

/*  */

.main_container_dark_light .nav__list .nav__listitemdrop a,
.main_container_dark_light .nav__list .nav__listitemdrop_assessor a,
.main_container_dark_light .nav__listitemdrop_assessor_focus a,
.main_container_dark_light .nav__listitemdrop_resources_focus a,
.main_container_dark_light .nav__listitemdrop_software_focus a,
.main_container_dark_light .nav__listitemdrop_students_focus a,
.main_container_dark_light .nav__listitemdrop_color_reset {
  color: #ffffff;
  cursor: pointer;
}
.main_container_dark_light .nav__list .nav__listitemdrop a:hover,
.main_container_dark_light .nav__list .nav__listitemdrop_assessor a:hover,
.main_container_dark_light .nav__listitemdrop_assessor_focus a:hover,
.main_container_dark_light .nav__listitemdrop_resources_focus a:hover,
.main_container_dark_light .nav__listitemdrop_software_focus a:hover,
.main_container_dark_light .nav__listitemdrop_students_focus a:hover,
.main_container_dark_light .nav__list .nav__listitemdrop_color_reset:hover {
  color: #0f6fd9;
  cursor: pointer;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1070px) {
  .nav {
    display: flex;
    justify-content: space-between;
    margin: 2rem 4rem;
  }
  .nav__list {
    display: none;
  }
  .menu-icon {
    display: block;
    width: 43px;
    height: 32px;
  }
}

@media screen and (max-width: 600px) {
  .nav {
    display: flex;
    justify-content: space-between;
    margin: 2rem 2rem;
  }

  .nav__listlogo img {
    width: 145.76px;
    height: 36px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
