.articles_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 100px;
}

.other_wrapper{
  min-height: 1300px;
}

@media screen and (max-width: 850px) {
  .articles_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}