.form_wrapp {
  display: flex;
  justify-content: center;
}
.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  z-index: 1;
}
.order_form {
  max-height: 700px;
  max-width: 760px;
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.input_small {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  width: 364px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  margin: 6px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0b323e;
}

.big {
  width: 100%;
  height: 128px;
}

.input_big {
  resize: none;
  padding: 10px 14px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 6px 0px;
  color: #0b323e;
}

.upload_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 16px 24px;
  width: 100%;
  height: 128px;
  background: #f5f5f5;
  border: 1px dashed #e4e7ec;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #0b323e;
}

.btn-warning {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: #f5f5f5;
  color: #0f6fd9;
  position: relative;
  padding: 11px 16px;
  font-size: 15px;
  border: 0;
  transition: 0.2s;
  overflow: hidden;
}

.btn-warning input[type='file'].input_file {
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: scale(3);
  opacity: 0;
}
.label_block {
  display: flex;
  justify-content: space-between;
  margin-right: 3%;
}
.privacy_container {
  display: flex;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.privacy_input {
  margin: 0 10px;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  border-radius: 6px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.privacy_input:checked {
  background-color: #0f6fd9;
}

.submit_btn {
  width: 60%;
  height: 48px;
  border: 1px solid #0f6fd9;
  box-sizing: border-box;
  /* margin-bottom: 1000px; */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
}

.submit {
  background: none;
  border: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.submit-disabled {
  width: 60%;
  height: 48px;
  background: #667085;
  border: 1px solid #667085;
  box-sizing: border-box;
  /* margin-bottom: 1000px; */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  margin-top: 20px;
}
.inp_label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.link__order_form {
  color: #0f6fd9;
  cursor: pointer;
  margin: 0 5px;
}

.h2_order_form {
  display: flex;
  justify-content: center;
}
.order_success {
  min-height: 50vh;
}

@media screen and (max-width: 800px) {
  .order_form {
    max-height: 900px;
    margin-top: 33px;
  }
  .link__order_form {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  .order_success {
    min-height: 0;
  }
}
@media screen and (max-width: 600px) {
  .input_small {
    width: 100%;
    margin: 6px 0px;
  }
  .big {
    height: 180px;
  }
  .upload_container {
    height: 180px;
  }
}

