.ergonimic_img {
  width: 100%;
  height: 100%;
}

.ergonimic_svg {
  max-width: 545px;
  max-height: 131px;
  width: 100%;
  height: 100%;
  margin: 56px 0;
}
@media screen and (max-width: 400px) {
  .ergonimic_svg {
    margin: 24px 0;
  }
}
