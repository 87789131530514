.about_us_img {
  width: 100%;
  height: 100%;
  margin-top: 56px;
}

@media screen and (max-width: 600px) {
  .about_us_img {
    margin: 32px 0 56px;
  }
}
