.mobilenav {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #0b323e;
    color: #ffffff;
    display: flex;
    position: fixed;
    z-index: 1;
}

.close {
    position: fixed;
    right: 10%;
    top: 4%;
    cursor: pointer;
}

#accordion {
    margin: 7rem 0 48px 0;
    width: 90%;
}

#accordion li {
    width: 100%;
    margin-bottom: 5px;
    padding: 1rem;
}

#accordion li .content a {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #ffffff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

#accordion li .link_mob {
    color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 30px;
}
#accordion li label {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 30px;
    cursor: pointer;
}
#accordion li label span {
    color: #fff;
}

#accordion li label span.active {
    color: #fff;
    transform: rotate(90deg);
}

#accordion label + input[type='radio'] {
    display: none;
}

#accordion .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    padding: 0 16px;
}

#accordion .content a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    margin: 10px;
}

#accordion label + input[type='radio']:checked + .content {
    max-height: 400px;
}

.border_mob {
    width: 95%;
    height: 1px;
    background: #284a55;
    margin: 0 20px;
}
.button_mob {
    width: 168px;
    height: 48px;
    background: linear-gradient(90deg, #1070da 15.32%, #5e26ff 65.18%);
    border-radius: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    margin-left: 20px;
}
.login_mob a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
}
