.nmh_rates_img_wrapp {
  display: flex;
  justify-content: center;
}
.nmh_rates_img {
  max-width: 637px;
  max-height: 637px;
  height: 100%;
  width: 100%;
  margin-top: 46px;
}

.download-nmh {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #0b323e;
}

@media screen and (max-width: 600px) {
  .download-nmh {
    font-size: 15px;
    line-height: 20px;
  }
}
