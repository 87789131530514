.tech_support {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tech_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 74px 0;
    width: 416px;
    max-width: 416px;
}

.tech_btn {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 2px solid #2194ba;
    box-sizing: border-box;
    margin: 0 0 24px 0;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2194ba;
    border-radius: 24px;
}

.tech_contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 120px;
}

@media screen and (max-width: 800px) {
   .error_tech{
    height: 100vh;
   }
  }
@media screen and (max-width: 600px) {
    .error_tech{
        height: 100%;
       }
    .tech_buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 33px 0;
        width: 312px;
    }

    .tech_btn {
        width: 100%;
        height: 48px;
        background: #ffffff;
        border: 2px solid #2194ba;
        box-sizing: border-box;
        margin: 0 0 18px 0;

        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2194ba;
        border-radius: 24px;
    }
    .tech_contact {
        margin-bottom: 56px;
    }
}
